import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { createClient } from 'contentful'
import Moment from 'react-moment'
import apiKeys from '../apikeys';
import Footer from "../components/Footer"
import Header from "../components/Header"
import BlogCard from '../components/Blog/BlogCard'
import './blog.scss'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

// markup
const BlogPage = () => {

  const [headerColor, setHeaderColor] = useState('dark');
  const [selectedComponent, setSelectedComponent] = useState(5);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    getBlogPosts()
  }, [])

  const getBlogPosts = () => {
    const client = createClient({
      space: apiKeys.CONTENTFUL_SPACEID,
      environment: 'master',
      accessToken: apiKeys.CONTENTFUL_APIKEY,
    })

    client.getEntries({ content_type: 'blogPost' })
    .then((response) => {
      setDocuments(response.items)
    })
    .catch(console.error)
  }

  return (
    <main className={"site"}>      
      <Helmet title={'Scorf'}>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name='title' content='Scorf'/>
          <meta name="description" content="Scorf construit un outil BI unique capable de comprendre la 'grammaire' comptable qui se cache derrière chaque base de données financière - Grand livre, écritures de journal. Cela lui permet de fournir des analyses avancées et un suivi en temps réel aux départements financiers des PME ainsi qu'aux analystes et consultants financiers externes." />
          <link rel="canonical" href="https://www.scorf.io/" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-8VFYNJHG71"></script>
          <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-8VFYNJHG71');`}}>            
          </script>
      </Helmet>
      <title>Scorf</title>
      <Header headerColor={headerColor} selectedComponent={selectedComponent}/>
      {documents.length > 0 && (
        <div className="pinnedBlogContainer container">
          <img src={documents[0].fields.image.fields.file.url} alt={documents[0].fields.image.fields.title} />
          <div className="infos">
            {documents[0].metadata.tags.length > 0 && <span>{documents[0].metadata.tags[0].sys.id}</span>}
            <h1><a href={`/blogpost${documents[0].fields.slug}`}>{documents[0].fields.title}</a></h1>
            <p>{documents[0].fields.metaDescription}</p>
            <Moment format="DD-MM-YYYY">{documents[0].sys.createdAt}</Moment>
          </div>
        </div>
      )}
      <div className="blogPostsContainer container">
        {documents.slice(1).map(doc => <BlogCard document={doc} />)}
      </div>
      <Footer setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
    </main>
  )
}

export default BlogPage
